
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import NeedHelp from "@/components/V2/NeedHelp/Index.vue";
import InputUrl from "@/components/InputUrl/Index.vue";
import ButtonInputUrl from "@/components/ButtonInputUrl/Index.vue";
import SelectUserComponent from "@/components/SelectUser/Index.vue";
import LongDescriptionHandlerComponent from "@/components/LongDescriptionHandler/Index.vue";
import ChipHackmetrix from "@/components/Chip/Index.vue";
import { UrlsInterface } from "@/store/modules/maintenance/MaintenanceModule";
import OverlayBackground from "@/components/OverlayBackground/Index.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    NeedHelp,
    InputUrl,
    ButtonInputUrl,
    SelectUserComponent,
    ChipHackmetrix,
    LongDescriptionHandlerComponent,
    OverlayBackground
  },
  computed: {
    ...mapGetters("MaintenanceModule", ["getSelectedActivity"]),
    ...mapGetters("DocumentModule", ["getDocumentDetailById"]),
    ...mapGetters("SteppersModule", ["getStepperStay"]),
    ...mapGetters("MaintenanceModule", { selectedActivity: "getSelectedActivity" }),
  },
})
export default class SideNavDocumentationComponent extends Vue {
  @Prop({ required: false }) responseData!: any;

  private business = this.$store.state.BusinessModule.BusinessState.businessSelected
    .company;
  private documentUrl = this.$store.state.DocumentModule.DocumentState.SelectedDocument
    .activity_urls;
  private gapControlList = this.$store.state.GapModule.GapState.controlList
    .gap_analysis_controls;

  public selectedActivity: any;
  public usersProfile: any;
  public openComments = true;
  public open = true;
  public urls_array: UrlsInterface[] = [
    {
      url: "",
    },
  ];

  private datarooms: string[] = [];
  private userObject = {};
  private data: any = {};
  private loading = false;
  private articles = {};
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];

  async mounted() {
    this.data.act_id = this.$route.params.activity_id;

    const userObject = {
      id: this.responseData.responsableId,
      full_name: this.responseData.responsableName,
    };

    this.userObject = userObject;
    this.loading = true
    const response = await this.$store.dispatch(
      'ActivitiesModule/getActivityById',
      this.data.act_id
    )

    this.articles = response.data.articles
    this.loading = false
  }

  private selectChip(value: any) {
    if (this.gapControlList) {
      if (!this.ADMIN_ROLES.some((role: string) => this.$store.state.AuthModule.AuthState.role.includes(role))){
        this.$router.push({
          name: "startupDetailControl",
          params: {
            id_control: value.id ? value.id.toString() : "null",
            id_category: value.category_id ? value.category_id.toString() : "null",
          },
          query: { redirect: "true" },
        });
      } else {
        this.$router.push({
          name: "editControl",
          params: {
            id: value.id,
            id_control: value.id ? value.id.toString() : "null",
            id_category: value.category_id ? value.category_id.toString() : "null",
          },
          query: { redirect: "true" },
        });
      }
    } else {
      this.loading = true;
      this.$store
        .dispatch("GapModule/getGapControlList", { categoryId: value.category_id })
        .then(() => {
          this.loading = false

          if (!this.ADMIN_ROLES.some((role: string) => this.$store.state.AuthModule.AuthState.role.includes(role))){
            this.$router.push({
              name: "startupDetailControl",
              params: {
                id_control: value.id ? value.id.toString() : "null",
                id_category: value.category_id ? value.category_id.toString() : "null",
              },
              query: { redirect: "true" },
            });
          } else {
            this.$router.push({
              name: "editControl",
              params: {
                id: value.id,
                id_control: value.id ? value.id.toString() : "null",
                id_category: value.category_id ? value.category_id.toString() : "null",
              },
              query: { redirect: "true" },
            });
          }
        });
    }
  }

  @Watch("responseData")
  changePreview() {
    this.userObject = {
      full_name: this.responseData.responsableName,
      id: this.responseData.responsableId,
    };
    this.responseData.activity_urls.forEach((element: any) => {
      element.url = element.link;
    });
    this.urls_array = this.responseData.activity_urls;
    this.$store.commit("DocumentModule/SET_URLS_ARRAY", this.urls_array);
  }

  private addUrl(value: any) {
    this.urls_array.push({ url: value, link: value });
    this.$emit("actUrl", this.urls_array);
    this.$store.commit("DocumentModule/SET_URLS_ARRAY", this.urls_array);
    this.data = {
      ...this.data,
      activity_urls_attributes: [
        {
          link: value,
        },
      ],
    };
    this.$store.dispatch("ActivitiesModule/updateActivity", this.data).then(() => {
      this.$emit("refresh");

      this.$mixpanel.track("URL añadida - Docs", {
        Actividad: this.$store.state.DocumentModule.DocumentState.SelectedDocument
          .activityName,
        URL: value,
      });
    });
  }

  private urlsAct(value: any, id: number) {
    let dataAct;

    if (!value.trim()) {
      dataAct = {
        ...this.data,
        activity_urls_attributes: [
          {
            id: id,
            link: value,
            _destroy: true,
          },
        ],
      };
    } else {
      dataAct = {
        ...this.data,
        activity_urls_attributes: [
          {
            id: id,
            link: value,
          },
        ],
      };
    }

    this.$store.dispatch("ActivitiesModule/updateActivity", dataAct).then(() => {
      this.$emit("refresh");
    });
  }

  private updateUser(value: any) {
    this.$mixpanel.track("Responsable asignado - Docs", {
      Actividad: this.$store.state.DocumentModule.DocumentState.SelectedDocument
        .activityName,
      Responsable: value,
    });
    this.$emit("actUser", value);

    const data: any = {
      act_id: this.$route.params.activity_id,
      user_id: value.id,
    };
    this.loading = true
      ;
    this.$store
      .dispatch("ActivitiesModule/updateActivity", data)
      .then(() => {
        this.loading = false

      })
      .catch(() => {
        this.loading = false

      });
  }
}


import { Vue, Component, } from 'vue-property-decorator'
import TextBox from '@/components/TextBox/Index.vue'
import { mapGetters, } from 'vuex'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {
    TextBox,OverlayBackground
  },
  computed: {
    ...mapGetters('DocumentModule', { urls: 'getUrlArray', }),
  },
})
export default class StepTreeDoc extends Vue {
  private Business = this.$store.state.BusinessModule.BusinessState.businessClient
  private description = ''
  private urls: any
  private loading = false;

  private nextStep () {
    this.loading = true

    const data = {
      companyId: this.Business.id,
      activityId: Number(this.$route.params.activity_id),
      description: this.description,
      urls: this.urls,
    }
    this.$mixpanel.track('Continuar - Descripcion cambios', {'Actividad': this.$store.state.DocumentModule.DocumentState.SelectedDocument.activityName, 'Descripcion': this.description,})
    this.$store
      .dispatch('StepperDocumentModule/nexStep', data)
      .then(() => {
        this.loading = false
        this.$emit('refresh')
      })
      .catch(() => {
        this.loading = false
      })
  }

  private beforeStep () {
    this.loading = true
    this.$mixpanel.track('Volver atras - Descripcion cambios', {'Actividad': this.$store.state.DocumentModule.DocumentState.SelectedDocument.activityName,})
    const data = {
      companyId: this.Business.id,
      activityId: Number(this.$route.params.activity_id),
    }

    this.$store
      .dispatch('StepperDocumentModule/postPreviousStep', data)
      .then(() => {
        this.loading = false
        this.$emit('refresh')
      })
      .catch(() => {
        this.loading = false
      })
  }

}


import { Vue, Component, Prop, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'

@Component({
  components: {},
  computed: {
    ...mapGetters('DocumentModule', ['getDocumentDetailById',]),
    ...mapGetters('DocumentModule', ['getDocumentDetailById',]),
    ...mapGetters('AuthModule', { getUserRole: 'getUserRole', }),
    ...mapGetters('BusinessModule', { getSelectedProduct: 'getSelectedProduct', }),
  },
})
export default class NavigationDocument extends Vue {
  @Prop({ required: false, }) readonly expiredValidate!: string

  public selectedActivity: any
  public getUserRole: any
  public getSelectedProduct: any

  private role = this.$store.state.AuthModule.AuthState.role

  private goBack () {
    this.$mixpanel.track('Volver a documentos')
    if (this.role.includes('Implementador')) {
      this.$router.push({ name: 'documentsClient', })
    } else {
      this.$router.push({ name: 'documents', })
    }
  }

  private next () {
    const data = this.$store.state.StepperDocumentModule.SteppersDocumentState.activitys
    const data2: any = []
    data.forEach((element: any) => {
      if (element.status === 'complies' && element.expirationDate) {
        data2.push(element)
      }
    })

    let id: any = this.$route.params.activity_id
    const index = data2.findIndex((act: any) => act.activityId == Number(id))

    if (data2.length === 1 && data2[0].activityId === Number(this.$route.params.activity_id)) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'No hay documentos finalizados siguientes',
      })
    } else {
      id = !data2 || data2.length > index + 1 ? data2[index + 1].activityId : data2[0].activityId
      const name = this.$route.path.includes('stepper') ? 'steppsDocuments' : 'maintenanceDetail'
      this.$mixpanel.track('Siguiente')
      this.$router.push({ name, params: { activity_id: id, }, })
      this.$emit('refresh')
    }
  }

  private back () {
    const data = this.$store.state.StepperDocumentModule.SteppersDocumentState.activitys
    const data2: any = []
    data.forEach((element: any) => {
      if (element.status === 'complies' && element.expirationDate) {
        data2.push(element)
      }
    })

    let id: any = this.$route.params.activity_id
    const index = data2.findIndex((act: any) => act.activityId == Number(id))

    if (data2.length === 1 && data2[0].activityId === Number(this.$route.params.activity_id)) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'No hay documentos finalizados anteriores',
      })
    } else {
      id = !data2 || data2.length > index - 1 ? data2[index - 1].activityId : data2[0].activityId
      const name = this.$route.path.includes('stepper') ? 'steppsDocuments' : 'maintenanceDetail'
      this.$mixpanel.track('Anterior')
      this.$router.push({ name, params: { activity_id: id, }, })
      this.$emit('refresh')
    }
  }
}

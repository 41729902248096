
import { Vue, Component, } from 'vue-property-decorator'
import UploadFile from '@/views/Shared/ImplementationDetail/Components/UploadFile.vue'
import { FileUploadInterface, } from '@/store/modules/dataroom/dataroomModule'
import { formatISO, } from 'date-fns'
import { mapGetters, } from 'vuex'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {
    UploadFile,
  },
  computed: {
    ...mapGetters('DocumentModule', { urls: 'getUrlArray', }),
  },
})
export default class StepOneDoc extends Vue {
  private loading = false;
  private Business = this.$store.state.BusinessModule.BusinessState.businessClient
  private fileToUpload: FileUploadInterface[] = []
  private urls: any
  private date: null | string | number | Date = null
  private locale = { weekDays: ['L', 'M', 'M', 'J', 'V', 'S', 'D',], }

  private refreshDocuments (template: FileUploadInterface[]) {
    this.fileToUpload = template
  }

  private allowedDates (date: string | number | Date) {
    const allDates = new Date()
    const currentDates = new Date(date)
    return currentDates < allDates
  }

  private nextStep () {
    this.loading = true

    let formatDate
    if (this.date) {
      formatDate = formatISO(new Date(this.date))
    } else {
      formatDate = formatISO(new Date())
    }

    const data = {
      approvedDate: formatDate,
      companyId: this.Business.id,
      activityId: Number(this.$route.params.activity_id),
      datarooms_data: this.fileToUpload,
      urls: this.urls,
    }

    this.$mixpanel.track('Continuar - Revisar y aprobar cambios', {'Actividad': this.$store.state.DocumentModule.DocumentState.SelectedDocument.activityName ,'Fecha Aprobacion': formatDate,})
    this.$store
      .dispatch('StepperDocumentModule/nexStep', data)
      .then(() => {
        this.loading = false
        this.$emit('refresh')
      })
      .catch(() => {
        this.loading = false
      })
  }

  private beforeStep () {
    this.loading = true

    const data = {
      companyId: this.Business.id,
      activityId: Number(this.$route.params.activity_id),
    }
    this.$mixpanel.track('Cancelar actualizar documentos', {'Actividad': this.$route.params.activityName,})
    this.$store
      .dispatch('StepperDocumentModule/postPreviousStep', data)
      .then(() => {
        this.loading = false
        this.$emit('refresh')
      })
      .catch(() => {
        this.loading = false
      })
  }
}

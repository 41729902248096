
import { Vue, Component } from "vue-property-decorator";
import TaskListContainerComponent from "@/components/TaskListContainer/Index.vue";
import ChipHackmetrix from "@/components/Chip/Index.vue";
import { mapGetters } from "vuex";
import CommentAreaComponent from "@/components/CommentArea/Index.vue";
import CommentBoxComponent from "@/components/CommentBox/Index.vue";
import Datepicker from "@/components/Calendar/Index.vue";
import SideNavDocuments from "@/components/V2/SideNavDocuments/Index.vue";
import NavigationDocument from "@/components/V2/NavigationDocuments/Index.vue";
import StepContainer from "./components/SteperContainer.vue";
import OverlayBackground from "@/components/OverlayBackground/Index.vue";

@Component({
  components: {
    TaskListContainerComponent,
    CommentBoxComponent,
    CommentAreaComponent,
    ChipHackmetrix,
    Datepicker,
    SideNavDocuments,
    NavigationDocument,
    StepContainer,
    OverlayBackground,
  },
  computed: {
    ...mapGetters("MaintenanceModule", ["getSelectedActivity", "getComments"]),
    ...mapGetters("MaintenanceModule", { selectedActivity: "getSelectedActivity" }),
    ...mapGetters("DocumentModule", { documentSelected: "getDocumentDetailById" }),
  },
})
export default class MaintenanceDetailComponent extends Vue {
  public showData = true;
  public selectedActivity: any;
  public openComments = true;
  public open = true;
  private Business = this.$store.state.BusinessModule.BusinessState.businessClient;
  private responseData: any = {};
  private activityName = this.$store.state.DocumentModule.DocumentState.SelectedDocument
    .activityName;
  private role = this.$store.state.AuthModule.AuthState.role;
  private loading = false;
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];
  mounted() {
    this.getActivityDetail();
  }

  private getActivityComments() {
    this.$store.dispatch(
      "MaintenanceModule/getActivitiesComments",
      this.$route.params.activity_id
    );
  }

  private sendMessage(message: string) {
    this.loading = true;
    this.$store
      .dispatch("ActivitiesModule/postActivitiesComment", {
        act_id: this.$route.params.activity_id,
        message,
      })
      .then(() => {
        this.loading = false;
        this.getActivityComments();
      })
      .catch(() => {
        this.loading = false;
        this.getActivityComments();
      });
  }

  private getActivityDetail() {
    this.loading = true;
    this.showData = false;

    const data = {
      company_id: this.Business.id,
      act_id: this.$route.params.activity_id,
    };

    this.$store
      .dispatch("DocumentModule/getDocumentById", data)
      .then((response) => {
        const data = response.data.data;

        this.responseData = data;

        if (data.step === null || data.step.queryName === "InitialStep") {
          this.loading = false;
          this.showData = true;
          if (!this.ADMIN_ROLES.some((role: string) => this.role.includes(role))){
            this.$router.push({
              name: "maintenanceDetail",
              params: { activity_id: this.$route.params.activity_id },
            });
          } else {
            this.$router.push({
              name: "maintenanceDetailClient",
              params: { activity_id: this.$route.params.activity_id },
            });
          }
        } else {
          this.loading = false;
          this.showData = true;
        }
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.showData = true;
      });
  }

  private next() {
    const data = this.$store.state.StepperDocumentModule.SteppersDocumentState.activitys;
    const data2: any = [];
    data.forEach((element: any) => {
      if (element.status === "complies" && element.expirationDate) {
        data2.push(element);
      }
    });

    let id: any = this.$route.params.activity_id;
    const index = data2.findIndex((act: any) => act.activityId == Number(id));

    if (
      data2.length === 1 &&
      data2[0].activityId === Number(this.$route.params.activity_id)
    ) {
      this.$store.dispatch("NotificactionsModule/OpenNotification", {
        message: "No hay documentos finalizados siguientes",
      });
    } else {
      id =
        !data2 || data2.length > index + 1
          ? data2[index + 1].activityId
          : data2[0].activityId;
      const name = this.$route.path.includes("stepper")
        ? "steppsDocuments"
        : "maintenanceDetail";
      this.$router.push({ name, params: { activity_id: id } });
      this.$emit("refresh");
    }
  }

  private back() {
    const data = this.$store.state.StepperDocumentModule.SteppersDocumentState.activitys;
    const data2: any = [];
    data.forEach((element: any) => {
      if (element.status === "complies" && element.expirationDate) {
        data2.push(element);
      }
    });

    let id: any = this.$route.params.activity_id;
    const index = data2.findIndex((act: any) => act.activityId == Number(id));

    if (
      data2.length === 1 &&
      data2[0].activityId === Number(this.$route.params.activity_id)
    ) {
      this.$store.dispatch("NotificactionsModule/OpenNotification", {
        message: "No hay documentos finalizados anteriores",
      });
    } else {
      id =
        !data2 || data2.length > index - 1
          ? data2[index - 1].activityId
          : data2[0].activityId;
      const name = this.$route.path.includes("stepper")
        ? "steppsDocuments"
        : "maintenanceDetail";
      this.$router.push({ name, params: { activity_id: id } });
      this.$emit("refresh");
    }
  }

  private goBack() {
    if (this.role.includes("Implementador")) {
      this.$router.push({ name: "documentsClient" });
    } else {
      this.$router.push({ name: "documents" });
    }
  }
}

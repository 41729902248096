
import { Vue, Component, } from 'vue-property-decorator'
import UploadFile from '@/views/Shared/ImplementationDetail/Components/UploadFile.vue'
import { FileUploadInterface, } from '@/store/modules/dataroom/dataroomModule'
import SelectFrecuency from '@/components/SelectFrecuency/Index.vue'
import { SelectInterface, Frecuency, } from '@/utils/Select'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"
import { mapGetters, } from 'vuex'

@Component({
  components: {
    UploadFile,
    SelectFrecuency,OverlayBackground
  },
  computed: {
    ...mapGetters('DocumentModule', { urls: 'getUrlArray', }),
    ...mapGetters('DocumentModule', ['getDocumentDetailById',]),
  },
})
export default class StepTwoDoc extends Vue {
  private Business = this.$store.state.BusinessModule.BusinessState.businessClient
  private urls: any
  private loading = false;
  private fileToUpload: FileUploadInterface[] = []

  private frecuency_value: SelectInterface[] = Frecuency
  private frecuency_selected = ''
  private checkEvery = this.$store.state.DocumentModule.DocumentState.SelectedDocument.checkEvery

  private refreshDocuments (template: FileUploadInterface[]) {
    this.fileToUpload = template
  }

  private selectedFrecuency (element: any) {
    this.frecuency_selected = element
  }

  private nextStep () {
    this.loading = true

    const data = {
      companyId: this.Business.id,
      activityId: Number(this.$route.params.activity_id),
      checkEvery: this.frecuency_selected === '' ? 'one_year' : this.frecuency_selected,
      datarooms_data: this.fileToUpload,
      urls: this.urls,
    }
   
    this.$mixpanel.track('Continuar - Subir documento actualizado', {
      Actividad: this.$store.state.DocumentModule.DocumentState.SelectedDocument.activityName,
    })
    this.$store
      .dispatch('StepperDocumentModule/nexStep', data)
      .then(() => {
        this.loading = false
        this.$emit('refresh')
      })
      .catch(() => {
        this.loading = false
      })
  }

  private beforeStep () {
    this.loading = true
    const data = {
      companyId: this.Business.id,
      activityId: Number(this.$route.params.activity_id),
    }
    this.$mixpanel.track('Volver atras - Subir documento actualizado', {
      Actividad: this.$store.state.DocumentModule.DocumentState.SelectedDocument.activityName,
    })
    this.$store
      .dispatch('StepperDocumentModule/postPreviousStep', data)
      .then(() => {
        this.$emit('refresh')
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }
}


import { Vue, Component, Prop, Watch, } from 'vue-property-decorator'
@Component({
  computed: {},
})
export default class TextBox extends Vue {
  @Prop({ required: true, }) readonly placeholder!: string
  private description = ''
  private maxCount = 480
  private characterCount = 0
  private hasError = false

  private countdown () {
    this.characterCount = this.description.length
    this.hasError = this.characterCount > 479
  }

  @Watch('description')
  show (): void {
    this.$emit('value', this.description)
  }
}


import { Vue, Component, } from 'vue-property-decorator'
import UploadFile from '@/views/Shared/ImplementationDetail/Components/UploadFile.vue'
import { FileUploadInterface, } from '@/store/modules/dataroom/dataroomModule'
import { mapGetters, } from 'vuex'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {
    UploadFile,OverlayBackground
  },
  computed: {
    ...mapGetters('DocumentModule', { urls: 'getUrlArray', }),
  },
})
export default class StepFourDoc extends Vue {
  private loading = false;

  private Business = this.$store.state.BusinessModule.BusinessState.businessClient
  private urls: any

  private fileToUpload: FileUploadInterface[] = []

  private refreshDocuments (template: FileUploadInterface[]) {
    this.fileToUpload = template
  }

  private nextStep () {
    this.loading = true

    const data = {
      companyId: this.Business.id,
      activityId: Number(this.$route.params.activity_id),
      datarooms_data: this.fileToUpload,
      urls: this.urls,
    }
    this.$mixpanel.track('Documentacion actualizada', {'Actividad': this.$store.state.DocumentModule.DocumentState.SelectedDocument.activityName,})
    this.$store
      .dispatch('StepperDocumentModule/nexStep', data)
      .then(() => {
        this.$router.push(`/startup/maintenance/${data.activityId}`)
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  private beforeStep () {
    this.loading = true

    const data = {
      companyId: this.Business.id,
      activityId: Number(this.$route.params.activity_id),
    }
    this.$mixpanel.track('Volver atras - Comunicar cambios', {'Actividad': this.$store.state.DocumentModule.DocumentState.SelectedDocument.activityName,})
    this.$store
      .dispatch('StepperDocumentModule/postPreviousStep', data)
      .then(() => {
        this.loading = false
        this.$emit('refresh')
      })
      .catch(() => {
        this.loading = false
      })
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.stepers),function(element,index){return _c('div',{key:index},[_c('div',{staticClass:"step-item"},[_c('div',{staticClass:"step-number relative"},[_c('div',{staticClass:"step",class:{ 'step-completed': _vm.documentDetail.step.queryName === element.tag }},[_c('span',[_vm._v(_vm._s(element.id))])])]),_c('div',{staticClass:"step-info relative ml-1"},[(_vm.documentDetail.step.queryName !== element.tag || _vm.role !== 'company')?_c('div',{staticClass:"name-step"},[_vm._v(" "+_vm._s(element.name)+" ")]):_vm._e(),(
            _vm.documentDetail.step.queryName === 'ReviewAndApproval' &&
            _vm.documentDetail.step.queryName === element.tag &&
            !_vm.ADMIN_ROLES.some(adminRole => _vm.role.includes(adminRole))
          )?_c('StepOneDoc',{on:{"refresh":function($event){return _vm.$emit('refresh')}}}):_vm._e(),(
            _vm.documentDetail.step.queryName === 'UploadFinalDocument' &&
            _vm.documentDetail.step.queryName === element.tag &&
            !_vm.ADMIN_ROLES.some(adminRole => _vm.role.includes(adminRole))
          )?_c('StepTwoDoc',{on:{"refresh":function($event){return _vm.$emit('refresh')}}}):_vm._e(),(
            _vm.documentDetail.step.queryName === 'DescribeChanges' &&
            _vm.documentDetail.step.queryName === element.tag &&
            !_vm.ADMIN_ROLES.some(adminRole => _vm.role.includes(adminRole))
          )?_c('StepTreeDoc',{on:{"refresh":function($event){return _vm.$emit('refresh')}}}):_vm._e(),(
            _vm.documentDetail.step.queryName === 'CommunicateChanges' &&
            _vm.documentDetail.step.queryName === element.tag &&
            !_vm.ADMIN_ROLES.some(adminRole => _vm.role.includes(adminRole))
          )?_c('StepFourDoc',{on:{"refresh":function($event){return _vm.$emit('refresh')}}}):_vm._e()],1)])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Vue, Component, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'

import StepOneDoc from './StepOneDoc.vue'
import StepTwoDoc from './StepTwoDoc.vue'
import StepTreeDoc from './StepTreeDoc.vue'
import StepFourDoc from './StepFourDoc.vue'

@Component({
  components: {
    StepOneDoc,
    StepTwoDoc,
    StepTreeDoc,
    StepFourDoc,
  },
  computed: {
    ...mapGetters('DocumentModule', { documentDetail: 'getDocumentDetailById', }),
    ...mapGetters('DocumentModule', ['getDocumentDetailById',]),
  },
})
export default class ImplementationDetailComponent extends Vue {
  private documentDetail: any
  public openComments = true
  public doneActivity = false
  private stepSelected = 'review_document'
  private role = this.$store.state.AuthModule.AuthState.role
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];
  private step_document_completed = [
    {
      id: 1,
      name: 'Revisar documentación y aprobar los nuevos cambios',
      tag: 'ReviewAndApproval',
    },
    {
      id: 2,
      name: 'Subir nuevo documento actualizado',
      tag: 'UploadFinalDocument',
    },
    {
      id: 3,
      name: 'Añadir descripción de los cambios',
      tag: 'DescribeChanges',
    },
    {
      id: 4,
      name: 'Comunicar los cambios al documento',
      tag: 'CommunicateChanges',
    },
  ]

  private step_document_alone = [
    {
      id: 1,
      name: 'Subir nuevo documento actualizado',
      tag: 'UploadFinalDocument',
    },
    {
      id: 2,
      name: 'Añadir descripción de los cambios',
      tag: 'DescribeChanges',
    },
  ]

  private step_document_comunication = [
    {
      id: 1,
      name: 'Subir nuevo documento actualizado',
      tag: 'UploadFinalDocument',
    },
    {
      id: 2,
      name: 'Añadir descripción de los cambios',
      tag: 'DescribeChanges',
    },
    {
      id: 3,
      name: 'Comunicar los cambios al documento',
      tag: 'CommunicateChanges',
    },
  ]

  private stepers: any[] = []

  mounted () {
    switch (this.documentDetail.documentTypes.length) {
    case 3:
      this.stepers = this.step_document_completed
      break
    case 2:
      this.stepers = this.step_document_comunication
      break
    case 1:
      this.stepers = this.step_document_alone
      break

    default:
      this.stepers = this.step_document_completed
      break
    }
  }
}


import { Component, Vue, Prop, Watch, } from 'vue-property-decorator'
import Datepicker from 'vue2-datepicker'
import { format, add, } from 'date-fns'

@Component({
  components: {
    Datepicker,
  },
})
export default class DatepickerComponent extends Vue {
  @Prop({ required: false, default: 'default', }) placeholder!: string
  @Prop({ required: false, default: undefined, }) value!: string
  @Prop({ required: false, default: false, }) disabled!: boolean

  private date = ''
  private changeDateWatch = ''
  private showDatePicker = false

  @Watch('value')
  updateValue () {
    if (this.value) {
      const dateAux = add(new Date(this.value), { days: 1, })
      this.date = format(new Date(dateAux), 'dd\'-\'MM\'-\'yyyy\'')
    } else this.date = ''
  }

  private changeDate () {
    this.$emit('eventDate', this.date)
  }
}

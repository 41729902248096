
import { TaskInterface, } from '@/store/types/Activities/ActivitiesGeneral'
import { Component, Prop, Vue, } from 'vue-property-decorator'

@Component
export default class TaskListContainerComponent extends Vue {
  @Prop({ required: false, default: false, }) readonly disabled!: boolean
  @Prop({
    required: false,
    default: {
      id: 'ID',
      name: ' Greyhound divisively hello coldly wonderfully marginally far upon excluding. max 3 líneas',
      description: 'Titulo de la activida maximo 2 lineas',
      completed: false,
      status: 0,
    },
  })
  task!: TaskInterface

  private replaceSpaces (v: string) {
    return v.replace(/ +/g, ' ')
  }
  private checkActivityTask () {
    this.$store.dispatch('ActivitiesModule/checkActivityTask', {
      task_id: this.task.id,
      completed: this.task.completed,
    })
    this.$mixpanel.track('Tarea Checkeada', {
      Tarea: this.task.name,
      Actividad: this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name,
      Empresa: this.$store.state.BusinessModule.BusinessState.businessSelected.name,
    })
  }
}
